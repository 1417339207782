import React from 'react'
import EventCard from './EventCard'

import Grid from '@material-ui/core/Grid';

export default class EventList extends React.Component {
  render() {
    let events = this.props.event

    return (
      <Grid container spacing={3}>
        {events &&
          events.map(({ node: event }, i) => (
            <Grid item xs={12} md={6}>
                <EventCard event={event} key={event.id} index={i}/>
            </Grid>
          ))}
      </Grid>
    )
  }
}
