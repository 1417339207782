import React from 'react'
import { graphql}  from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import Breadcrumb from '../../components/Breadcrumb'
import EventList from '../../components/Events/EventList'

import Container from '@material-ui/core/Container'


export default class EventIndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: events } = data.allMarkdownRemark;
    let location = this.props.location;
    let today = new Date();

    let futureEvents = events.filter(function(event){
        let eventDate = new Date(event.node.frontmatter.date)
        return eventDate > today

        
    })

    let helmet = (
      <Helmet>
        <title>Event | Kvarntakt20</title>
        <meta
          name="description"
          content='Håll dig uppdaterad om dans och event i Dalarna och övriga Sverige'
          />
      </Helmet>
    )


    return (
      <Layout location={location}>
        {helmet || ''}
        <div className="event-title main-title d-flex d-md-none">
          <h2>Event</h2>
        </div>
        <Breadcrumb path={location.pathname} title={'Kommande Event'}/>
        <section className="section event">
          <Container className="event-wrapper">
            <EventList event={futureEvents}/>
          </Container>
        </section>
      </Layout>
    )
  }
}

export const EventIndexPageQuery = graphql`
  query eventPageQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "event-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date
            description
            location
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
