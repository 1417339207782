import React from  'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';


export default function EventCard ({event, index}) {
    const { slug } = event.fields;
    const { title, date, image, description, location} = event.frontmatter;
    let postImage = image ? image.childImageSharp.fluid.src : null;
    let formatedDate = date.split('T')[0]

    return(
        <div className="event-card-wrapper">
            <div className="event-card-img"></div>
            <Card
                className='event-card'>
                <Hidden xsDown>
                { postImage && postImage !== null ? 
                <CardMedia
                   className='image'
                   image={postImage}
                   height="140"
                   /> 
                   : ''
                   }
                </Hidden>
                <div className={postImage !== null ? 'content' : 'content-full'}>
                    <CardHeader
                        className="title"
                        title={title}
                    />
                    <CardContent className="subtitle">
                        <p>Vart: {location}</p>
                        <p>När: {formatedDate}</p>
                    </CardContent>
                    <CardContent>
                        <Typography paragraph>
                            {description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <a href={slug} alt={title}>
                            <Button size="medium" color="primary" className='card-button'>
                                Till eventet
                                <Icon>arrow_right_alt</Icon>
                            </Button>
                        </a>
                    </CardActions>
                </div>
            </Card>
        </div>
    )
}